@import '~antd/dist/antd.min.css';
@import "~react-image-gallery/styles/css/image-gallery.css";
.ant-btn-primary {
  background-color: #7BA566;
  border-color: #7BA566;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #7BA566;
}
html {
  height: 100%;
}
body {
  height: 100%;
  width: 100%;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}
body a {
  color: #7BA566;
}
body a:hover {
  color: #7BA566;
  text-decoration: underline;
}
img {
  max-width: 100%;
}
div.map img {
  width: unset;
}
.ant-menu {
  border: none;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #7BA56650;
}
.ant-menu .ant-menu-item,
.ant-menu .ant-menu-submenu {
  color: #7BA566;
}
.ant-menu .ant-menu-item.ant-menu-item-selected,
.ant-menu .ant-menu-submenu.ant-menu-item-selected,
.ant-menu .ant-menu-item.ant-menu-submenu-selected,
.ant-menu .ant-menu-submenu.ant-menu-submenu-selected,
.ant-menu .ant-menu-item:hover,
.ant-menu .ant-menu-submenu:hover {
  color: #7BA566;
  border-color: #7BA566;
}
.ant-menu .ant-menu-item .ant-menu-submenu-title:hover,
.ant-menu .ant-menu-submenu .ant-menu-submenu-title:hover {
  color: #7BA566;
}
.ant-menu .ant-menu-item .ant-menu-submenu-title:active,
.ant-menu .ant-menu-submenu .ant-menu-submenu-title:active {
  background-color: #7BA56650;
}
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
  color: #7BA566;
  border-color: #7BA566;
}
.ant-list-split.ant-list-bordered {
  border-color: #7BA566;
}
.ant-list-split.ant-list-bordered .ant-list-header {
  border-color: #7BA566;
  color: #7BA566;
}
.ant-list-split + .ant-list-split {
  margin-top: 10px;
}
.ant-list-split .ant-list-item {
  border-color: #7BA566;
}
.ant-list-split .ant-list-item .ant-list-item-meta-title {
  color: #7BA566;
}
.ant-list-split.events .ant-list-item .ant-list-item-meta-description {
  margin-left: 18px;
}
.ant-table-wrapper + .ant-table-wrapper {
  margin-top: 10px;
}
.ant-table-wrapper .ant-table.ant-table-bordered .ant-table-title {
  border-color: #7BA566;
}
.ant-table-wrapper .ant-table.ant-table-bordered tr {
  border-left: 1px solid #7BA566;
}
.ant-table-wrapper .ant-table.ant-table-bordered .ant-table-thead {
  border-top: 1px solid #7BA566;
}
.ant-table-wrapper .ant-table.ant-table-bordered .ant-table-thead tr th {
  border-color: #7BA566;
  background: #7BA56650;
  color: #7BA566;
}
.ant-table-wrapper .ant-table.ant-table-bordered .ant-table-tbody tr:hover td {
  background: #7BA56650;
}
.ant-table-wrapper .ant-table.ant-table-bordered .ant-table-tbody tr td {
  border-color: #7BA566;
}
header {
  border-bottom: 1px solid #7BA566;
}
slot > .ant-row > div + div:not(:last-child) {
  border-bottom: 1px solid #7BA566;
}
slot .ant-row > div {
  padding: 20px;
}
slot section p .figure {
  padding: 10px 25px;
  display: block;
}
slot section p .figure .figcaption {
  text-align: right;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  display: block;
}
slot section p .figure .figcaption > span {
  display: block;
}
div#root {
  position: relative;
  height: auto;
  min-height: 100%;
}
div#root > div {
  height: auto;
  min-height: 100%;
}
header .ant-row {
  padding-bottom: 2px;
}
header .ant-row > div {
  height: 70px;
  position: relative;
}
header .ant-row > div:nth-child(2) {
  padding-left: 20px;
}
header .ant-row > div .ant-menu {
  position: absolute;
  bottom: 0;
}
header nav .ant-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50%;
  margin-left: -50%;
  margin-top: -25%;
  background: transparent;
  border: none;
  color: #7BA566;
  text-shadow: none;
  box-shadow: none;
}
header nav .ant-btn i svg {
  width: 2.5em;
  height: 2.5em;
}
header #logo {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50%;
  margin-left: -50%;
}
footer {
  border-top: 1px solid #7BA566;
  background: #7BA566;
  color: white;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}
footer .ant-row > div:nth-child(2) {
  padding: 20px;
  text-align: right;
}
.ant-drawer .ant-drawer-title {
  color: #7BA566;
}
.ant-drawer .ant-drawer-body {
  padding: 0;
}
.ant-drawer .ant-drawer-body .ant-menu:not(.ant-menu-horizontal) .ant-menu-item:after {
  border: none;
}
.ant-drawer .ant-drawer-body .ant-menu:not(.ant-menu-horizontal) .ant-menu-item.ant-menu-item-selected {
  background: #7BA56650;
}
.ant-drawer .ant-drawer-body .ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-drawer .ant-drawer-body .ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
  background: -webkit-gradient(linear, left top, right top, from(#7BA566), to(#7BA566));
  background: -webkit-linear-gradient(left, #7BA566, #7BA566);
  background: linear-gradient(to right, #7BA566, #7BA566);
}
.ant-fullcalendar-cell.ant-fullcalendar-today .ant-fullcalendar-date {
  border-top-color: #7BA566;
}
.ant-fullcalendar-cell.ant-fullcalendar-selected-day .ant-fullcalendar-date .ant-fullcalendar-value {
  color: #7BA566;
  font-weight: bold;
}
.ant-fullcalendar-cell .ant-fullcalendar-date .ant-fullcalendar-value:hover {
  background-color: #7BA56650;
  color: white;
}
.ant-fullcalendar-cell ul.events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.ant-fullcalendar-cell ul.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
.ant-collapse {
  border-color: #7BA566;
}
.ant-collapse > .ant-collapse-item {
  background-color: #7BA56650;
  border-color: #7BA566;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #7BA566;
  font-weight: bold;
}
@media only screen and (min-width: 768px) {
  div#root > div {
    padding-bottom: 62px;
  }
  header .ant-carousel {
    min-height: 300px;
  }
  slot > .ant-row > div + div:not(:last-child) {
    border-bottom: none;
    border-right: 1px solid #7BA566;
    height: 100%;
    min-height: 100%;
  }
  footer {
    height: 62px;
  }
}
@media only screen and (max-width: 1042px) {
  div#root > div {
    padding-bottom: 82px;
  }
  footer {
    height: 82px;
  }
}
@media only screen and (max-width: 768px) {
  div#root > div {
    padding-bottom: 0;
  }
  header .ant-carousel {
    min-height: 150px;
  }
  footer {
    height: auto;
    position: relative;
  }
}
.pulse {
  animation: pulse 1s ease infinite;
  animation-direction: alternate;
  -webkit-animation-name: pulse;
  animation-name: pulse;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.ant-btn-primary:focus,
.ant-btn-primary:hover {
  color: #7BA566;
  background-color: #7BA56650;
  border-color: #7BA566;
}
.calendar:not(.ant-fullcalendar) {
  border-radius: 4px;
  border: 1px solid #7BA566;
}
.calendar:not(.ant-fullcalendar) .ant-fullcalendar {
  border-color: #7BA566;
}
.calendar:not(.ant-fullcalendar) .ant-fullcalendar-header .ant-radio-group {
  display: none;
}
.calendar:not(.ant-fullcalendar) .ant-fullcalendar-header .ant-select + .ant-select {
  margin-left: 10px;
}
.ant-fullcalendar-today .ant-fullcalendar-value {
  box-shadow: inset 0 0 0 1px #7BA566;
}
.ant-fullcalendar-cell.ant-fullcalendar-selected-day .ant-fullcalendar-date .ant-fullcalendar-value {
  background-color: #7BA566;
  color: white;
}
.ant-select-selection {
  border-color: #7BA566;
  color: #7BA566;
}
.ant-select-selection .ant-select-arrow {
  color: #7BA566;
}
.ant-select-dropdown .ant-select-dropdown-menu-item:hover {
  background-color: #7BA56650;
}
.ant-badge-dot {
  background-color: #7BA566;
}
.ant-badge-status-dot:not(.ant-badge-status-default):after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid white;
  content: "";
  -webkit-animation: antStatusProcessing 1.2s ease-in-out infinite;
  animation: antStatusProcessing 1.2s ease-in-out infinite;
}
.ant-badge-status-dot:not(.ant-badge-status-default).ant-badge-status-error:after {
  border-color: red;
}
.ant-badge-status-dot:not(.ant-badge-status-default).ant-badge-status-success {
  border-color: #7BA566;
}
.ant-badge-status-dot:not(.ant-badge-status-default).ant-badge-status-success:after {
  border-color: #7BA566;
}
.ant-collapse-content-box section + section {
  margin-top: 20px;
}
.ant-table-row-expand-icon {
  border-color: #7BA566;
}
.ant-table-row-expand-icon:after {
  color: #7BA566;
}
.ant-table-row-level-1 .ant-table-row-indent,
.ant-table-row-level-1 .ant-table-row-expand-icon {
  display: none;
}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #7BA56650;
}
.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  padding: 10px;
}
.ant-back-top {
  bottom: 65px;
}
@media only screen and (min-width: 576px) {
  .ant-back-top {
    bottom: 45px;
  }
}
@media only screen and (min-width: 1600px) {
  header .ant-row > div {
    height: 85px;
  }
}
article > section + section {
  margin-top: 20px;
}
.ant-card-cover {
  height: 179px;
}
.ant-card-cover > * {
  max-height: 100%;
}
.ant-card-bordered .ant-card-body {
  border-top: 1px solid #e8e8e8;
}
.ant-card-bordered .ant-card-body .ant-card-meta-title {
  font-size: 12px;
}
